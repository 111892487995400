import OpenAI from "openai";

const openai = new OpenAI({ apiKey: process.env.REACT_APP_OPENAI_API_KEY, dangerouslyAllowBrowser: true });

export async function getAnswer(question, qType, skill){
    let model, content;
    if (qType === 'normal3') {
        model = 'gpt-3.5-turbo-0125';
        if (typeof skill === 'undefined') { 
          content = "You are a helpful assistant."; 
        } else {
          content = "Please act as a " + skill + " expert"
        }
        console.log('gpt-3.5-turbo-0125');
        console.log(content);
      } else if (qType === 'normal4') {
        model = 'gpt-4-0125-preview'
        if (typeof skill === 'undefined') { 
          content = "You are a helpful assistant."; 
        } else {
          content = "Please act as a " + skill + " expert"
        }
        console.log('gpt-4-0125-preview');
        console.log(content);
      } else if (qType === 'mcq') {
        model = 'gpt-4-0125-preview'
        if (typeof skill === 'undefined') { 
          content = "Please select the most appropriate answers for the given MCQs"; 
        } else {
          content = "Please act as a " + skill + " expert and select me the most appropriate answers for the given MCQs"
        }
        console.log('MCQ - gpt-4-0125-preview');
        console.log(content);
      } else if (qType === 'short') {
        model = 'gpt-4-0125-preview'
        if (typeof skill === 'undefined') { 
          content = "Please Write 100% unique and human-written short answer in English. Also, Write the answer in formal style that is easy for non-native English audiences. Makesure to asnswers are short"; 
        } else {
          content = "Please act as a " + skill + " expert. Write 100% unique and human-written short answer in English. Also, Write the answer in formal style that is easy for non-native English audiences."
        }
        console.log('Short - gpt-4-0125-preview');
        console.log(content);
      } else if (qType === 'long') {
        model = 'gpt-4-0125-preview'
        if (typeof skill === 'undefined') { 
          content = "Please Write 100% unique, creative and human-written answer in English. Always write in an active voice. Make sure the answer is plagiarism free. Write a content fit check and pass ai detection tools. Write content with a high amount of propensity, perplexity and burstiness. Write the answer in formal style that is easy for non-native English audiences."; 
        } else {
          content = "Please act as a " + skill + " expert. Write 100% unique, creative and human-written answer in English. Always write in an active voice. Make sure the answer is plagiarism free. Write a content fit check and pass ai detection tools. Write content with a high amount of propensity, perplexity and burstiness. Write the answer in formal style that is easy for non-native English audiences."
        }
        console.log('Long - gpt-4-0125-preview');
        console.log(content);
      } else if (qType === 'custom') {
        model = 'gpt-4-0125-preview'
        content = "Please act as a cybersecurity research reporter and write a complete report for given cyber attack insident"; 
        console.log('Custom - gpt-4-0125-preview');
        console.log(content);
      } else {
        model = 'gpt-3.5-turbo-0125'
        content = "You are a helpful assistant."; 
        console.log('gpt-3.5-turbo-0125');
        console.log(content);
      }
    const response = await openai.chat.completions.create({
        model: model,
        messages: [
        {
            "role": "system",
            "content": content
            // "content": "Please act as a openai api expert and give me the answers" 
            // "content": "Please act as a AWS and Terraform expert and provide answers for the given questions"
            // "content": "Please act as a cybersecurity expert who speaks fluent English. Write 100% unique, creative and human-written report in English. Always write in an active voice. Use MLA format. Create a detailed long form report. Use engaging elaborative strength forward language. Suitable for grade 9 students. Do not include any personal opinions in the report. Make sure the report is plagiarism free. Write a content fit check and pass ai detection tools. Write content with a high amount of propensity, perplexity and burstiness."
            // "content": "You're a cybersecurity expert with fluent English. Craft a unique, creative, and human-like report on cybersecurity, adhering to MLA format. Aim for clarity and engagement, using active voice throughout. The report should be detailed, tailored for ninth graders, and free from personal opinions. Ensure originality to pass plagiarism checks and AI detection tools. Focus on delivering content with depth, clarity, and dynamic expression."
            // "content": "Please act as a cybersecurity expert. Write 100% unique, creative and human-written answer in English. Always write in an active voice. Make sure the answer is plagiarism free. Write a content fit check and pass ai detection tools. Write content with a high amount of propensity, perplexity and burstiness. Write the answer in formal style that is easy for non-native English audiences."
        },
        {
            "role": "user",
            "content": question
        }
        ],
        temperature: 0.7,
        max_tokens: 4096,
        top_p: 0.9,
    });
    return response.choices[0].message.content;
}