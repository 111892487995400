import './App.css';
import React, { useState } from 'react';
import { getAnswer } from './openai';
import parse from 'html-react-parser';
import Markdown from 'markdown-to-jsx';

function App() {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [question, setQuestion] = useState("");
  const [user, setUser] = useState("");
  const [skill, setSkill] = useState();
  const [messages, setMessages] = useState("");
  const [qType, setQType] = useState("normal3");

  const handleSend = async () => {
    if(user===process.env.REACT_APP_PW) {
      setIsButtonDisabled(true);
      const response = await getAnswer(question, qType, skill);
      setMessages(parse(response));
      setIsButtonDisabled(false);
      console.log(response);
    } else if (user==="kt") {
      setMessages("Uncaught runtime errors: <br/>ERROR 429 You exceeded your current quota, please check your plan and billing details. <br/>For more information on this error, read the docs: https://platform.openai.com/docs/guides/error-codes/api-errors.");
    } else {
      setMessages("Unauthorized User")
    }
  }

  const handleSelectInputChange = (event) => {
    setQType(event.target.value);
  };

  const calculateTextAreaHeight = () => {
    const minHeight = 50;
    const maxHeight = 200;
    const lines = user.split('\n').length;
    const newHeight = lines * 20 + minHeight;
    return newHeight > maxHeight ? maxHeight : newHeight;
  };

  return (
    <div className="App">
      <label htmlFor="select-type">Select Input :</label>
      <select id="select-type" name="select-type" value={qType} onChange={handleSelectInputChange} required>
        <option value="normal3">Normal-3</option>
        <option value="normal4">Normal-4</option>
        <option value="mcq">MCQ</option>
        <option value="short">Short Answer</option>
        <option value="long">Long Answer</option>
        <option value="custom">Custom</option>
      </select> <br/>
      <input type='text' placeholder='Act As' value={skill} onChange={(e)=>{setSkill(e.target.value)}} /><br/>

        <input type='password' placeholder='User' value={user} onChange={(e)=>{setUser(e.target.value)}} /><br/>
        <textarea value={question} onChange={(e)=>{setQuestion(e.target.value)}} style={{ height: calculateTextAreaHeight(), width: '80%' }}  placeholder='Your Question' />
        <br/>
        <button className='send' onClick={handleSend} disabled={isButtonDisabled}>{isButtonDisabled ? 'Processing...' : 'Click me'}</button>
        <br/><br/>
        <Markdown>{messages}</Markdown>
    </div>
  );
}

export default App;